export const environment = {
  baseEndpoint: 'https://api-dev.talkingstick.app/',
  buildNumber: "28778163",  
  versionNumber: "2.1.7",  
  environment_deploy: "dev",  
  rayGunKey: 'hqaZDpkENyp0WOVqpDhdg',  
  localStorageKey: '2de1786e-607e-49ec-b270-b33a3b5c1327',  
  cryptoKey: '39e3cc17-f053-4b36-a840-80f93d07061a',  
  posthogToken: 'phc_FxfNWKWqYxa9nm24KsWccHzMR776akapxZWqhHjFTGJ',  
  orgCode: '',

  firebaseConfig: {
  apiKey: 'AIzaSyCLDHbBwV_P4NaoQKLKDw5YFHUpUmVD_14',  
  authDomain: 'talking-stick-dev-f04da.firebaseapp.com',  
  projectId: 'talking-stick-dev-f04da',  
  storageBucket: 'talking-stick-dev-f04da.appspot.com',  
  messagingSenderId: '777694147085',  
  appId: '1:777694147085:web:c35f67b3a28e2514295b44',  
  vapidKey: 'BMkP8WV0peP5ZBQbr6jiFatOC2NLUWBd-IvJrlYYBzD6yqRHUXaE2S_5ftt2yD7el7krAYvinfMfzAKWiy_IcHU',  
  },
};
