import React from 'react';
import type { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import './orgHeadConfig';
import './i18n';

import App from './App';
import { environment } from './environment/environment';
import { store } from './store';

const container = document.getElementById('root');

// https://posthog.com/docs/integrate/client/js#config
const options: Partial<PostHogConfig> = {
  api_host: 'https://app.posthog.com',
  property_denylist: ['email', 'pass', 'retype_pass', 'new_pass', 'old_pass'],
  person_profiles: 'identified_only',
};

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PostHogProvider apiKey={environment.posthogToken} options={options}>
          <App />
        </PostHogProvider>
      </Provider>
    </React.StrictMode>,
  );
}

// required for using Camera on web (https://capacitorjs.com/docs/web/pwa-elements)
defineCustomElements(window);
